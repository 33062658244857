import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_QUERY } from '../../api/api_helpers'
import * as API_ENDPOINTS from '../../api/api_endpoints'

export const answersApi = createApi({
	reducerPath: 'answersApi',
	baseQuery: fetchBaseQuery(BASE_QUERY),
	tagTypes: ['ANSWERS_DASHBOARD'],
	endpoints: (builder) => ({
		getDashboardAnswers: builder.query({
			query: (param) => ({
				url: !param ? `${API_ENDPOINTS.DASHBOARD_DATA_ANSWERS}` : `${API_ENDPOINTS.DASHBOARD_DATA_ANSWERS}?personalityid=${param}`,
				method: "GET"
			}),
			providesTags: () => [{ type: 'ANSWERS_DASHBOARD', id: 'LIST' }],
			transformResponse: (response) => response.data
		}),
		answerToQuestions: builder.mutation({
			query: (body) => ({
				url: API_ENDPOINTS.CREATE_ANSWER,
				method: "POST",
				body,
			}),
		})
	})
})

export const { useGetDashboardAnswersQuery, useAnswerToQuestionsMutation } = answersApi