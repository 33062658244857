
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PublicRoute = ({isLogged,  component: Component, restricted, ...rest}) => {
	return (
		<Route {...rest} render={props => (
			isLogged && restricted
			? <Redirect to="/admincp" />
			: <Component {...props} />
		)} />
	);
};

export default PublicRoute;