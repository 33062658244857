import { MDBDataTable } from "mdbreact"
import React, { useEffect, useState } from "react"
import {
	ButtonDropdown,
	Card, CardBody,
	Col,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Row
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useGetDashboardAnswersQuery } from "../../redux/slices/answersSlice"
import { useGetPersonalityTypesListQuery } from "../../redux/slices/personalityTypes.slice"
import "./datatables.scss"

const DatatableTables = () => {
	const [personalitiesMenuOpen, isPersonalitiesMenuOpen] = useState(false);
	const [dashboardData, setDashboardData] = useState({});
	const [personalityParam, setPersonalityParam] = useState(null);
	const [selectedPersonalityType, setSelectedPersonalityType] = useState(null);
	const { isLoading, isError, isSuccess: isSuccessDashboardAnswers, data: answersDashboardData } = useGetDashboardAnswersQuery(personalityParam);
	const { isLoading: isLoadingPersonalityTypes, isSuccess: isSuccessPersonalityTypes, data: personalityTypesData } = useGetPersonalityTypesListQuery();

	const handleChangePersonalityType = (value) => {
		setPersonalityParam(value._id);
		setSelectedPersonalityType(value.label)
	}

	const median = (numbers) => {
		const sorted = numbers.slice().sort((a, b) => a - b);
		const middle = Math.floor(sorted.length / 2);
		
		return (sorted.length % 2 === 0)
			? (sorted[middle - 1] + sorted[middle]) / 2
			: sorted[middle];
	  }

	useEffect(() => {
		let columns = [],
			rows = [],
			answersQuestions = {},
			answersValues = [],
			sumAnswers = 0;
			
		columns = [
			{ label: 'Age', field: 'age', sort: "asc", width: 100 },
			{ label: 'School level', field: 'schoolLevel', width: 200 },
			{ label: 'Profession', field: 'profession', width: 130 },
			{ label: 'Region', field: 'region', width: 150 },
			{ label: 'Ville', field: 'ville', width: 150 },
			{ label: 'Family situation', field: 'familySituation', width: 200 },
		];
		if (isSuccessDashboardAnswers) {
			answersDashboardData.map((data, i) => {
				answersQuestions = {
					'age': data._id.age,
					'schoolLevel': data._id.schoolLevel,
					'profession': data._id.profession,
					'region': data._id.region,
					'ville': data._id.ville,
					'familySituation': data._id.familySituation,
				};
				answersValues = [];
				sumAnswers = 0;

				for(const answer of data.answers) {
					answersQuestions = { ...answersQuestions, [answer.question.code]: answer.choice.personalityTypeChoices.value };
					i == 0 && columns.push({ label: answer.question.code, field: answer.question.code, sort: "asc", width: 80 });
					answersValues.push(answer.choice.personalityTypeChoices.value);
					sumAnswers += parseInt(answer.choice.personalityTypeChoices.value);
				}
				i == 0 && columns.push(
					{ label: 'Somme', field: 'somme', sort: "asc", width: 100 },
					{ label: 'Moyenne', field: 'moyenne', sort: "asc", width: 100 },
					{ label: 'Mediane', field: 'mediane', sort: "asc", width: 100 }
				);
				rows.push({
					...answersQuestions,
					'somme': sumAnswers,
					'moyenne': (sumAnswers / data.answers.length).toFixed(2),
					'mediane': median(answersValues).toFixed(2)
				});
			})
			setDashboardData({
				columns,
				rows
			})
		}
	}, [isSuccessDashboardAnswers, answersDashboardData])

	useEffect(() => {
		if (isSuccessPersonalityTypes) {
			setSelectedPersonalityType(personalityTypesData[0].label)
			setPersonalityParam(personalityTypesData[0]._id);
		}
	}, [isSuccessPersonalityTypes]);

	return (
		<React.Fragment>
			<div className="page-content">
				<Breadcrumbs title="Panel" breadcrumbItem="Réponses" />
				<div className="btn-group me-1 mt-2">
					<span>Filter by personality type : {" "} </span>
					<ButtonDropdown
						isOpen={personalitiesMenuOpen}
						toggle={() => isPersonalitiesMenuOpen(!personalitiesMenuOpen)}>
						<DropdownToggle
							style={{ borderRadius: '4px', marginLeft: '6px', marginBottom: '6px', marginTop: '-6px'}}
							caret
							color="secondary"
							className="btn btn-secondary btn-sm">
							{selectedPersonalityType} {" "}
							<i className="mdi mdi-chevron-down" />
						</DropdownToggle>
						<DropdownMenu>
							{isSuccessPersonalityTypes && personalityTypesData.map((e, i) => (<DropdownItem key={i} onClick={() => handleChangePersonalityType(e)}>{ e.label }</DropdownItem>))}
						</DropdownMenu>
					</ButtonDropdown>
				</div>{" "}
				<Row>
					<Col className="col-12">
						<Card>
							<CardBody>
								{/* <CardTitle>Stripped example </CardTitle>
								<CardSubtitle className="mb-3">
									mdbreact DataTables has most features enabled by default, so
									all you need to do to use it with your own tables is to call
										the construction function:{" "}
									<code>&lt;MDBDataTable striped /&gt;</code>.
									</CardSubtitle> */}
								<MDBDataTable responsive striped bordered data={dashboardData} />
							</CardBody>
						</Card>
					</Col>
				</Row>
			</div>
		</React.Fragment>
	)
}

export default DatatableTables
