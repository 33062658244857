import { useEffect } from 'react';

const QuestionChoice = (props) => {
	const { language, data, currentAnswer, handleCurrentAnswer, handleNextQuestion } = props;

	useEffect(() => {
		if (data.active === true) {
			handleCurrentAnswer(null)
		}
	}, [data])

	const handleRadioChange = (e) => {
		const value = e.target.value;
		const choiceAnswer = data.question.choices.find((e) => e.choiceFr === value)
		
		handleCurrentAnswer({
			'question': data.question._id,
			'choice': {
				'choiceFr': choiceAnswer.choiceFr,
				'choiceAr': choiceAnswer.choiceAr,
				'personalityTypeChoices': choiceAnswer.personalityTypeChoices.map((e) => ({
					'personalityType': e.personalityType._id,
					'value': e.value
				}))
			}
		});
	}

	// useEffect(() => {
	// 	if (currentAnswer) {
	// 		handleNextQuestion();
	// 	}
	// }, [currentAnswer])

	return (
		<div className="container py-5 px-4 px-lg-5 my-auto" key={data.question._id}>
			<div className="row py-5 py-sm-4 bg-logo-sign">
				<div className="col-12 text-center mx-auto">
					<h2 className="text-10 fw-600 text-white mb-4 text-anime">{language === 'fr' ? data.question.descriptionFr : data.question.descriptionAr}</h2>
				</div>
				<div className="col-md-8 col-lg-8 col-xl-8 mx-auto text-center">
					<div className="wrapper-radio">
						<div className="box">
							{
								data.question.choices.map((choice, index) => (
									<div key={`${data.question._id}-${index}`}>
										<input
											id={`option-${index+1}`}
											type="radio"
											name="select"
											value={choice.choiceFr}
											onChange={(e) => { handleRadioChange(e); }}/>
										<label
											htmlFor={`option-${index+1}`}
											className={`option-${index+1} element-animation${index+1}`}>
											<div className="dot"></div>
											<div className="text">{language === 'fr' ? choice.choiceFr : choice.choiceAr}</div>
										</label>
									</div>
								))
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default QuestionChoice;