import PropTypes from 'prop-types'
import React from "react"

import { connect, useSelector } from "react-redux"
import { Redirect, Route, BrowserRouter as Router, Switch } from "react-router-dom"

// Import Routes all

// Import all middleware

// layouts Format
import HorizontalLayout from "./components/HorizontalLayout/"
import VerticalLayout from "./components/VerticalLayout/"

// Import scss
import "./assets/scss/stylesheet.css"
import "./assets/scss/theme.scss"

import fakeBackend from "./helpers/AuthType/fakeBackend"
import Login from './pages/Authentication/Login'
import Logout from './pages/Authentication/Logout'
import Dashboard from './pages/Dashboard'
import HomeQuiz from './pages/Quiz/HomeQuiz'
import PrivateRoute from './routes/privateRoute'
import PublicRoute from './routes/publicRoute'

// Activating fake backend
fakeBackend()

const App = props => {
  function getLayout() {
    let layoutCls = VerticalLayout

    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const Layout = getLayout()
  const profile = useSelector((store) => store.userProfile);

  return (
    <React.Fragment>
      <Router>
        <Switch>
          {/* {
            !profile?.isLogged 
            ? (
              authRoutes.map((route, idx) => (
                <Authmiddleware
                  path={route.path}
                  layout={NonAuthLayout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={false}
                />
              ))
            )
            : (
              userRoutes.map((route, idx) => (
                <Authmiddleware
                  path={route.path}
                  layout={Layout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  exact
                />
              ))
            )
          } */}
          
          <PublicRoute isLogged={profile.isLogged} component={HomeQuiz} path="/" restricted={false} exact/>
          <PublicRoute isLogged={profile.isLogged} component={Login} path="/cpadmin/login" restricted={true} exact/>
          <PublicRoute isLogged={profile.isLogged} component={Logout} path="/logout" exact/>
          <PrivateRoute isLogged={profile.isLogged} component={Dashboard} path="/admincp" layout={Layout} exact/>
          <Route path="*" component={() => <Redirect to={{ path: "/" }}/> }/>
        </Switch>
      </Router>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)