import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import {
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from "reactstrap"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import user4 from "../../../assets/images/users/avatar-4.jpg"
import { logout } from "../../../redux/slices/profileSlice"

const ProfileMenu = props => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [menu, setMenu] = useState(false)
	const [username, setusername] = useState("Admin")
	const logoutAction = () => dispatch(logout());

	const handleLogout = () => {
		logoutAction();
		history.push("/login");
	}

	useEffect(() => {
		if (localStorage.getItem("authUser")) {
			if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
				const obj = JSON.parse(localStorage.getItem("authUser"))
				setusername(obj.displayName)
			} else if (
				process.env.REACT_APP_DEFAULTAUTH === "fake" ||
				process.env.REACT_APP_DEFAULTAUTH === "jwt"
			) {
				const obj = JSON.parse(localStorage.getItem("authUser"))
				setusername(obj.username)
			}
		}
	}, [props.success])

	return (
		<React.Fragment>
			<Dropdown
				isOpen={menu}
				toggle={() => setMenu(!menu)}
				className="d-inline-block"
			>
				<DropdownToggle
					className="btn header-item waves-effect"
					id="page-header-user-dropdown"
					tag="button"
				>
					<img
						className="rounded-circle header-profile-user"
						src={user4}
						alt="Header Avatar"
					/>{" "}
					<span className="d-none d-xl-inline-block ms-1">{username}</span>{" "}
					<i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>{" "}
				</DropdownToggle>
				<DropdownMenu className="dropdown-menu-end">
					<DropdownItem tag="a" href="/#">
						{" "}
						<i className="bx bx-user font-size-16 align-middle me-1"></i>{" "}
						{props.t("Voir le profil")}{" "}
					</DropdownItem>
				 
					<DropdownItem tag="a" href="#">
						<span className="badge bg-success float-end">11</span><i
							className="bx bx-wrench font-size-16 align-middle me-1"></i>{" "}
						{props.t("Paramètres")}
					</DropdownItem>
				 
					<div className="dropdown-divider" />
					<Link to="/logout" className="dropdown-item text-danger" onClick={handleLogout}>
						<i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>{" "}
						<span>{props.t("Se déconnecter")}</span>
					</Link>
				</DropdownMenu>
			</Dropdown>
		</React.Fragment>
	)
}

ProfileMenu.propTypes = {
	success: PropTypes.any,
	t: PropTypes.any
}

const mapStatetoProps = state => {
	const { error, success } = state.Profile
	return { error, success }
}

export default withRouter(
	connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)