import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	language: "fr",
	step: 0,
	answers: [],
}

const sessionSlice = createSlice({
	name: 'sessionSlice',
	initialState,
	reducers: {
		changeStepQuestion: (state) => {
			if (state.step == 0) {
				state.step += 1;
				return state;
			}
			if (typeof state.answers[state.step - 1] == "undefined" || typeof state.answers[state.step - 1] == undefined) {
			} else {
				state.step += 1;
			}
		},
		changeLanguage: (state, action) => {
			state.language = action.payload.language;
		},
		answerToQuestion: (state, action) => {
			const found = state.answers.some((answer) => answer.question == action.payload.question);
			
			!found && state.answers.push({
				'question': action.payload.question,
				'choice': action.payload.choice
			})
		},
		resetSession: (state) => {
			state.answers = [];
			state.step = 0;
			state.language = 'fr';
		}
	}
})

export const { changeStepQuestion, changeLanguage, answerToQuestion, resetSession } = sessionSlice.actions
export default sessionSlice.reducer