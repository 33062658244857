import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ isLogged, layout: Layout, component: Component, ...rest}) => {
	return (
		<Route {...rest} render={props => (
			isLogged
			? <Layout><Component {...props} /></Layout>
			: <Redirect to="/cpadmin/login" />
		)} />
	);
};

export default PrivateRoute;