import axios from "axios"
import storage from "redux-persist/lib/storage";

export const API_URL = process.env.REACT_APP_API_URL

const axiosApi = axios.create({ 'baseURL': API_URL });

axiosApi.interceptors.response.use(
	response => response,
	error => Promise.reject(error)
)

axiosApi.interceptors.request.use(
	async (config) => {
		config.headers = {
			Accept: "application/json",
		};
		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

export const get = async (url, config = {}) => axiosApi.get(url, { ...config }).then(response => response.data);

export const post = async (url, data, config = {}) => axiosApi.post(url, { ...data }, { ...config }).then(response => response.data);

export const put = async (url, data, config = {}) => axiosApi.put(url, { ...data }, { ...config }).then(response => response.data);

export const patch = async (url, data, config = {}) => axiosApi.patch(url, { ...data }, { ...config }).then(response => response.data);

export const del = async (url, data = {}) => axiosApi.delete(url, { data }).then(response => response.data);

export const BASE_QUERY = {
	'baseUrl': API_URL,
	prepareHeaders: async (headers) => {
		const session = await storage.getItem("persist:session") || null;;
		const profile = typeof JSON.parse(session)?.userProfile !== undefined ? null : JSON.parse(JSON.parse(session).userProfile);
		const token = profile?.token;
		token && headers.set("Authorization", `Bearer ${token}`);
		headers.set("Accept", "application/json");
		return headers;
	}
}