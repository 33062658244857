import { configureStore } from "@reduxjs/toolkit"
import { setupListeners } from '@reduxjs/toolkit/query'
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistStore } from 'redux-persist';
import createSagaMiddleware from "redux-saga"
import thunk from "redux-thunk"
import { answersApi } from "../redux/slices/answersSlice";
import { personalityTypesApi } from "../redux/slices/personalityTypes.slice";
import { profileApi } from "../redux/slices/profileSlice";
import { questionsApi } from "../redux/slices/questionsSlice";
import persistedReducer from "./reducers"
import rootSaga from "./sagas"

const sagaMiddleware = createSagaMiddleware()

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({
		serializableCheck: {
			ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
		}
	}).concat([thunk, sagaMiddleware, profileApi.middleware, personalityTypesApi.middleware, questionsApi.middleware, answersApi.middleware])
})

sagaMiddleware.run(rootSaga)
setupListeners(store.dispatch)

export const persistor = persistStore(store)