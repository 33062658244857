// * AUTHENTICATION
export const AUTH_LOGIN = "/auth/login";
// * PROFILE
export const USER_FIND = "/users/find";

// * QUESTIONS
export const LIST_QUESTIONS = "/questions/list";
export const FIND_QUESTION = "/questions/find";
export const CREATE_QUESTION = "/questions/create";
export const UPDATE_QUESTION = "/questions/update";
export const UPDATE_DELETE = "/questions/delete";

// * ANSWERS
export const CREATE_ANSWER = "/answers/create";

// * PERSONALITY TYPES
export const LIST_PERSONALITY_TYPES = "/personalities-types/list";


export const DASHBOARD_DATA_ANSWERS = "/participants/list";