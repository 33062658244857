import { createSlice } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_QUERY } from '../../api/api_helpers'
import * as API_ENDPOINTS from '../../api/api_endpoints'

export const profileApi = createApi({
	reducerPath: 'profileApi',
	baseQuery: fetchBaseQuery(BASE_QUERY),
	tagTypes: ['Profile'],
	endpoints: (builder) => ({
		loginAccount: builder.mutation({
			query: (body) => ({
				url: API_ENDPOINTS.AUTH_LOGIN,
				method: "POST",
				body,
			}),
			transformResponse: (response) => response.data,
		}),
		getProfile: builder.mutation({
			query: (body) => ({
				url: API_ENDPOINTS.USER_FIND,
				method: "POST",
				body
			}),
			transformResponse: (response) => response.data,
			providesTags: () => [{ type: 'Profile', id: 'LIST' }]
		})
	})
})

export const { useLoginAccountMutation, useGetProfileQuery } = profileApi;

const initialState = {
	isLogged: false,
	username: null,
	token: null
}

const profileSlice = createSlice({
	name: 'profileSlice',
	initialState,
	reducers: {
		login: (state, action) => {
			state.isLogged = true;
			state.username = action.payload.username;
			state.token = action.payload.token;
		},
		logout: (state) => {
			state.isLogged = false;
			state.username = null;
			state.token = null;
		}
	}
});

export const { login, logout } = profileSlice.actions;
export default profileSlice.reducer;