import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_QUERY } from '../../api/api_helpers'
import * as API_ENDPOINTS from '../../api/api_endpoints'

export const questionsApi = createApi({
	reducerPath: 'questionsApi',
	baseQuery: fetchBaseQuery(BASE_QUERY),
	tagTypes: ['Questions'],
	endpoints: (builder) => ({
		getQuestions: builder.query({
			query: () => API_ENDPOINTS.LIST_QUESTIONS,
			transformResponse: (response) => response.data,
			providesTags: () => [{ type: 'Questions', id: 'LIST' }]
		}),
	})
})

export const { useGetQuestionsQuery } = questionsApi