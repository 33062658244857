import usFlag from "../assets/images/flags/us.jpg"
import french from "../assets/images/flags/french.jpg"
import germany from "../assets/images/flags/germany.jpg"
import italy from "../assets/images/flags/italy.jpg"
import russia from "../assets/images/flags/russia.jpg"
import morocco from "../assets/images/flags/morocco.png"

const languages = {
  fr: {
    label: "Français",
    flag: french,
  },
  ar: {
    label: "Maroc",
    flag: morocco,
  },
}

export default languages
