import React, { useEffect } from "react"
import { Row, Col, Container } from "reactstrap"
import { Link, Redirect, useHistory } from "react-router-dom"
import { AvForm, AvField } from "availity-reactstrap-validation"
import logo from "../../assets/images/logo-sm-dark.svg"
import { useDispatch, useSelector } from "react-redux"
import { login, useLoginAccountMutation } from "../../redux/slices/profileSlice"

const Login = (props) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const loginAction = ({ username, token }) => dispatch(login({ username, token }));
	const [loginAccount, { isSuccess: isLoginSuccess, isLoading: isLoginLoading, isError: isLoginError, error: errorOnLogin, data: dataLogin }] = useLoginAccountMutation();
	
	useEffect(() => {
		document.body.className = "authentication-bg";
		return function cleanup() {
			document.body.className = "";
		};
	});
	
	const handleValidSubmit = (e, values) => {
		loginAccount(values)
	}

	useEffect(() => {
		if (isLoginSuccess) {
			loginAction({ 'username': dataLogin.user.username, 'token': dataLogin.payload.token });
			history.push('/admincp');
		}
	}, [isLoginSuccess])

	return (
		<React.Fragment>
			<div className="home-btn d-none d-sm-block">
				<Link to="/" className="text-dark">
					<i className="fas fa-home h2" />
				</Link>
			</div>
			<div className="account-pages my-5 pt-sm-5">
				<Container>
					<Row className="justify-content-center">
						<Col md={8} lg={6} xl={5}>
							<div className="card overflow-hidden">
								<div className="bg-login text-center">
									<div className="bg-login-overlay"></div>
									<div className="position-relative">
										<h5 className="text-white font-size-20">Welcome Back !</h5>
										<p className="text-white-50 mb-0">Sign in to continue to Diezze.</p>
										<Link to="/" className="logo logo-admin mt-4">
											<img src={logo} alt="" height="30" />
										</Link>
									</div>
								</div>
								<div className="card-body pt-5">
									<div className="p-2">
										<AvForm
											className="form-horizontal"
											onValidSubmit={(e, v) => { handleValidSubmit(e, v)}}>
											<div className="mb-3">
												<AvField
													name="username"
													label="Username"
													className="form-control"
													placeholder="Enter your username"
													type="username"
													required
												/>
											</div>
											<div className="mb-3">
												<AvField
													name="password"
													label="Password"
													type="password"
													placeholder="Enter your password"
													required
												/>
											</div>
											<div className="mt-3">
												<button
													className="btn btn-primary w-100 waves-effect waves-light"
													type="submit">
													Log In
												</button>
											</div>
											<div className="mt-4 text-center">
												<Link to="/forgot-password" className="text-muted"><i
													className="mdi mdi-lock me-1"></i> Forgot your password?</Link>
											</div>
										</AvForm>

									</div>
								</div>
							</div>
							<div className="mt-5 text-center">
								<p>© {new Date().getFullYear()} Diezze</p>
							</div>
						</Col>
					</Row>

				</Container>
			</div>
		</React.Fragment>
	)
}

export default Login;

// const mapStateToProps = state => {
// 	const { error } = state.Login
// 	return { error }
// }

// export default withRouter(
// 	connect(mapStateToProps, { loginUser, apiError, socialLogin })(Login)
// )

// Login.propTypes = {
// 	// error: PropTypes.any,
// 	// history: PropTypes.object,
// 	// loginUser: PropTypes.func,
// 	// socialLogin: PropTypes.func
// }