import { useEffect } from "react";
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';

const QuestionRange = (props) => {
	const { language, data, currentAnswer, rangeValue, setRangeValue, handleCurrentAnswer, handleNextQuestion } = props;
	useEffect(() => {
		if (data.active === true) {
			handleCurrentAnswer({
				'question': data.question._id,
				'choice': {
					'choiceFr': null,
					'choiceAr': null,
					'personalityTypeChoices': data.question.choices[0].personalityTypeChoices.map((e) => ({ 'personalityType': e.personalityType._id, 'value': 0 }))
				}
			})
			let randomRangeValue;
			randomRangeValue = (Math.random() * 6 - 3).toFixed(0);
			
			while (true) {
				if (randomRangeValue != -0)
					break;
				randomRangeValue = (Math.random() * 6 - 3).toFixed(0);
			}
			setRangeValue(randomRangeValue);
		}
	}, [data])

	useEffect(() => {
		if (currentAnswer) {
			handleCurrentAnswer({
				...currentAnswer,
				'choice': {
					...currentAnswer.choice,
					'personalityTypeChoices': data.question.choices[0].personalityTypeChoices.length > 1
						? data.question.choices[0].personalityTypeChoices.map((e) => (
							(rangeValue >= 0 || rangeValue < 0) && e.value < 0
							? { 'personalityType': e.personalityType._id, 'value': rangeValue }
							: { 'personalityType': e.personalityType._id, 'value': -1 * rangeValue }
						))
						: data.question.choices[0].personalityTypeChoices.map((e) => ({ 'personalityType': e.personalityType._id, 'value': rangeValue }))
				}
			});
		}
	}, [rangeValue]);

	return (
		<div className="container py-5 px-4 px-lg-5 my-auto" key={data.question._id}>
			<div className="row py-5 py-sm-4 bg-logo-sign">
				<div className="col-12 text-center mx-auto">
					<h2 className="text-10 fw-600 text-white mb-4 text-anime">{language === 'fr' ? data.question.descriptionFr : data.question.descriptionAr}</h2>
				</div>
				<div className="col-md-8 col-lg-8 col-xl-8 mx-auto text-center">
					<div className="subscribe-form">
						<div className="p-3">
							{
								data.question.choices[0].personalityTypeChoices[0].value < 0
								? (
									<>
										<span className="float-start mt-5 text-white text-4 fw-600">{language === 'fr' ? data.question.choices[0].choiceFr : data.question.choices[0].choiceAr}</span>{" "}
										<span className="float-end mt-5 text-white text-4 fw-600">{language === 'fr' ? data.question.choices[1].choiceFr : data.question.choices[1].choiceAr}</span>
									</>
								) : (
									<>
										<span className="float-start mt-5 text-white text-4 fw-600">{language === 'fr' ? data.question.choices[1].choiceFr : data.question.choices[1].choiceAr}</span>{" "}
										<span className="float-end mt-5 text-white text-4 fw-600">{language === 'fr' ? data.question.choices[0].choiceFr : data.question.choices[0].choiceAr}</span>
									</>
								)
							}
							<Slider
								tooltip={false}
								value={rangeValue}
								handleLabel={rangeValue}
								min={data.question.choices[0].personalityTypeChoices[0].value < 0 ? data.question.choices[0].personalityTypeChoices[0].value : data.question.choices[1].personalityTypeChoices[0].value}
								max={data.question.choices[0].personalityTypeChoices[0].value > 0 ? data.question.choices[0].personalityTypeChoices[0].value : data.question.choices[1].personalityTypeChoices[0].value}
								labels={{
									"-1": "|",
									"-2": "|",
									"-3": "|",
									0: "|",
									1: "|",
									2: "|",
									3: "|",
								}}
								orientation="horizontal"
								onChange={value => {
									setRangeValue(value);
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default QuestionRange;