import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_QUERY } from '../../api/api_helpers'
import * as API_ENDPOINTS from '../../api/api_endpoints'

export const personalityTypesApi = createApi({
	reducerPath: 'personalityTypesApi',
	baseQuery: fetchBaseQuery(BASE_QUERY),
	tagTypes: ['PERSONALITY_TYPES'],
	endpoints: (builder) => ({
		getPersonalityTypesList: builder.query({
			query: () => ({
				url: API_ENDPOINTS.LIST_PERSONALITY_TYPES,
				method: "GET"
			}),
			providesTags: () => [{ type: 'PERSONALITY_TYPES', id: 'LIST' }],
            transformResponse: (response) => response.data 
		})
	})
})

export const { useGetPersonalityTypesListQuery } = personalityTypesApi;