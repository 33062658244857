import React, { useEffect, useState } from "react";
import toast, { Toaster } from 'react-hot-toast';
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Progress, Row } from "reactstrap";
import storage from "redux-persist/lib/storage";
import AGREABILITE_IMG from "../../assets/images/AGREABILITE.gif";
import CONSCIENCIEUX_IMG from "../../assets/images/CONSCIENCIEUX.gif";
import EXTRAVERSION_IMG from "../../assets/images/EXTRAVERSION.gif";
import OUVERTURE_IMG from "../../assets/images/OUVERTURE.gif";
import { Loading } from "../../components/Loading/Loading";
import QuestionChoice from "../../components/Questions/QuestionChoice";
import QuestionRange from "../../components/Questions/QuestionRange";
import i18n from "../../i18n";
import { useAnswerToQuestionsMutation } from '../../redux/slices/answersSlice';
import { useGetQuestionsQuery } from "../../redux/slices/questionsSlice";
import { answerToQuestion, changeLanguage, changeStepQuestion, resetSession } from "../../redux/slices/sessionSlice";

function HomeQuiz(props) {
	const dispatch = useDispatch();
	const session = useSelector((store) => store.session);
	const initialData = {
		age: null,
		gender: null,
		schoolLevel: null,
		profession: null,
		region: null,
		ville: null,
		familySituation: null
	}
	const [loading, setLoading] = useState(true);
	const [submited, setSubmited] = useState(false);
	const [answer, setAnswer] = useState(null);
	const [progressValue, setProgressValue] = useState(0);
	const [rangeValue, setRangeValue] = useState(0);
	const [villes, setVilles] = useState(null);
	const [questionsList, setQuestionsList] = useState([]);
	const [activeQuestion, setActiveQuestion] = useState(null);
	const [data, setData] = useState(initialData);
	const [modal, setModal] = useState(false);
	const { data: questions, isLoading: isQuestionListLoading, isSuccess, isError, error } = useGetQuestionsQuery();
	const [answerToQuestions, { isSuccess: isSubmitSuccess, isLoading: isSubmitAnswersLoading, isError: isErrorWhileAnswer, error: errorOnSubmitAnswers, data: dataParticipant }] = useAnswerToQuestionsMutation();
	
	const toggle = () => setModal(!modal);
	const changeLanguageAction = (language) => dispatch(changeLanguage({ language }));
	const changeStepQuestionAction = (step) => dispatch(changeStepQuestion({ step }));
	const answerQuestionAction = ({ question, choice }) => dispatch(answerToQuestion({ question, choice }));
	const resetSessionAction = () => dispatch(resetSession());

	let regionVilles = {
		[props.t("Rabat-Salé-Kenitra")]: [
			props.t("Rabat"), props.t("Salé"), props.t("Skhirat-Temara"), props.t("Kénitra"), props.t("Khemisset"), props.t("Sidi Kacem"), props.t("Sidi Slimane"),
		],
		[props.t("Casablanca-Settat")]: [
			props.t("Casablanca"), props.t("Mohammedia"), props.t("El Jadida"), props.t("Nouaceur"), props.t("Médiouna"), props.t("Benslimane"), props.t("Berrechid"), props.t("Settat"), props.t("Sidi Bennour")
		],
		[props.t("Beni Mellal-Khénifra")]: [
			props.t("Béni Mellal"), props.t("Azilal"), props.t("Fkih Ben Salah"), props.t("Khénifra"), props.t("Khouribga")
		],
		[props.t("Fès-Meknès")]: [
			props.t("Fès"), props.t("Meknès"), props.t("Sefrou"), props.t("Taounate"), props.t("Moulay Yacoub"), props.t("Taza"), props.t("Boulmane"), props.t("El Hajeb"), props.t("Ifrane")
		],
		[props.t("Marrakech-Safi")]: [
			props.t("Marrakech"), props.t("Chichaoua"), props.t("Kelâat Es-Sraghna"), props.t("Essaouira"), props.t("Safi"), props.t("Ben Guerir"), props.t("Youssoufia")
		],
		[props.t("Tanger-Tétouan-Al Hoceïma")]: [
			props.t("Tanger-Assilah"), props.t("Mdiq-Fnideq"), props.t("Tétouan"), props.t("Larache"), props.t("Al Hoceima"), props.t("Chefchaouen"), props.t("Ouezzane")
		],
		[props.t("Oriental")]: [
			props.t("Oujda"), props.t("Nador"), props.t("Driouch"), props.t("Jerada"), props.t("Berkane"), props.t("Guercif"), props.t("Figuig")
		],
		[props.t("Drâa-Tafilalet")]: [
			props.t("Errachidia"), props.t("Ouarzazate"), props.t("Midelt"), props.t("Tinghrir"), props.t("Zagora")
		],
		[props.t("Souss-Massa")]: [
			props.t("Agadir"), props.t("Inezgane"), props.t("Ait Bahia"), props.t("Taroudant"), props.t("Tiznit"), props.t("Tata")
		],
		[props.t("Guelmim-Oued Noun")]: [
			props.t("Guelmin"), props.t("Assa-Zag"), props.t("Tan-Tan"), props.t("Sidi Ifni"),
		],
		[props.t("Laâyoune-Sakia El Hamra")]: [
			props.t("Lâayoune"), props.t("Boujdour"), props.t("Tarfaya"), props.t("Es Smara"),
		],
		[props.t("Dakhla-Oued Ed-Dahab")]: [
			props.t("Dakhla"), props.t("Lagouira"), props.t("Oued Ed-Dahab")
		]
	}
	const personalityTypesData = {
		"O": {
			'gif': OUVERTURE_IMG,
			'firstTitle': props.t("Innovateur"),
			"secondTitle": props.t("Ouverture d'esprit, tolérance, curiosité"),
			'data': [
				{ "title": props.t("O_title_1"), "descr": props.t("O_descr_1") },
				{ "title": props.t("O_title_2"), "descr": props.t("O_descr_2") },
				{ "title": props.t("O_title_3"), "descr": props.t("O_descr_3") },
				{ "title": props.t("O_title_4"), "descr": props.t("O_descr_4") },
				{ "title": props.t("O_title_5"), "descr": props.t("O_descr_5") },
				{ "title": props.t("O_title_6"), "descr": props.t("O_descr_6") },
				{ "title": props.t("O_title_7"), "descr": props.t("O_descr_7") },
				{ "title": props.t("O_title_8"), "descr": props.t("O_descr_8") },
				{ "title": props.t("O_title_9"), "descr": props.t("O_descr_9") },
			]
		},
		"C": {
			'gif': CONSCIENCIEUX_IMG,
			'firstTitle': props.t("Bosseur"),
			"secondTitle": props.t("Consciencieux, responsable, fiable."),
			'data': [
				{ "title": props.t("C_title_1"), "descr": props.t("C_descr_1") },
				{ "title": props.t("C_title_2"), "descr": props.t("C_descr_2") },
				{ "title": props.t("C_title_3"), "descr": props.t("C_descr_3") },
				{ "title": props.t("C_title_4"), "descr": props.t("C_descr_4") },
				{ "title": props.t("C_title_5"), "descr": props.t("C_descr_5") },
				{ "title": props.t("C_title_6"), "descr": props.t("C_descr_6") },
				{ "title": props.t("C_title_7"), "descr": props.t("C_descr_7") },
			]
		},
		"E": {
			'gif': EXTRAVERSION_IMG,
			'firstTitle': props.t("Aventurier"),
			"secondTitle": props.t("Extraverti, communicatif, sociable."),
			'data': [
				{ "title": props.t("E_title_1"), "descr": props.t("E_descr_1") },
				{ "title": props.t("E_title_2"), "descr": props.t("E_descr_2") },
				{ "title": props.t("E_title_3"), "descr": props.t("E_descr_3") },
				{ "title": props.t("E_title_4"), "descr": props.t("E_descr_4") },
			]
		},
		"A": {
			'gif': AGREABILITE_IMG,
			'firstTitle': props.t("Visionnaire"),
			"secondTitle": props.t("Agréable, empathique, diplomate."),
			'data': [
				{ "title": props.t("A_title_1"), "descr": props.t("A_descr_1") },
				{ "title": props.t("A_title_2"), "descr": props.t("A_descr_2") },
				{ "title": props.t("A_title_3"), "descr": props.t("A_descr_3") },
				{ "title": props.t("A_title_4"), "descr": props.t("A_descr_4") },
			]
		}
	}

	const handleChangeLanguage = (lang) => {
		changeLanguageAction(lang);
		i18n.changeLanguage(lang)
		localStorage.setItem("I18N_LANGUAGE", lang)
		setVilles([]);
	}

	const updateActivatdQuestion = (step) => {
		setQuestionsList(questions.map((e, index) => (
			index + 1 === step
			? { 'index': step, 'question': e, 'active': true }
			: { 'index': index + 1, 'question': e, 'active': false }
		)));
	}

	const handleNextQuestion = () => {
		let questionToBeAnswered,
			value = parseFloat(progressValue) + 1.79;
		
		if (!answer && session.step !== 0) {
			toast.error("Please select a choice", { 'duration': 2000, 'position': "top-center" })
		} else if (session.step <= questionsList.length) {
			if (session.step === 0) {
				setLoading(true);
				setTimeout(() => {
					setLoading(false);
				}, 2500)
			} else if (session.step > 0) {
				setRangeValue(0);
				answerQuestionAction({ 'question': answer.question, 'choice': answer.choice });
				questionToBeAnswered = questionsList.find(e => e.active === true);
				questionToBeAnswered.question.type === 'radio' && setAnswer(null);
			}
			if (session.step <= questionsList.length) {
				setProgressValue(parseFloat(value).toFixed(2));
				changeStepQuestionAction()
			}
		}
	}

	const updateData = e => {
		setData({ ...data, [e.target.name]: e.target.value });
		if (e.target.name === 'region') {
			setVilles([]);
			setVilles(regionVilles[e.target.value])
		}
	}

	const handleSubmitAnswers = () => {
		// * Post answers
		answerToQuestions({ 'answers': session.answers, ...data });
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
		}, 2500)
	}

	useEffect(() => {
		if (isQuestionListLoading) {
			setLoading(true);
		}
	}, [isQuestionListLoading]);

	useEffect(() => {
		if (!isSubmitAnswersLoading && isErrorWhileAnswer && errorOnSubmitAnswers) {
			toast.error('Error while submit answers... !', {
				duration: 3000,
				position: 'top-center',
			});
		}
	}, [isErrorWhileAnswer, errorOnSubmitAnswers]);

	useEffect(() => {
		if (!isSubmitAnswersLoading && isSubmitSuccess && dataParticipant) {
			setSubmited(true);
			toast.success('Submit with success !', {
				duration: 3000,
				position: 'top-center',
			});
			// * Remove session
			storage.removeItem('persist:session');
			setProgressValue(0)
			resetSessionAction();
			changeStepQuestionAction(0);
			setData(initialData);
			updateActivatdQuestion(0);
		}
	}, [isSubmitSuccess, isSubmitAnswersLoading]);

	useEffect(() => {
		if (isSuccess && questions && questions.length !== 0) {
			setTimeout(() => {
				setLoading(false);
			}, 2500)
			setProgressValue(((session.step / questions.length) * 100).toFixed(2))
			updateActivatdQuestion(session.step);
		}
	}, [isSuccess, questions]);

	useEffect(() => {
		questions && setQuestionsList(questions.map((e, index) => (
			index + 1 === session.step
			? { 'index': session.step, 'question': e, 'active': true }
			: { 'index': index + 1, 'question': e, 'active': false }
		)));
	}, [session.step])

	useEffect(() => {
		if (questionsList ) {
			const activaQuestion = questionsList.find((e) => e.active);
			setActiveQuestion(activaQuestion);
		}
	}, [questionsList]);

	const renderFooterComponent = () => (
		<footer className="container text-center">
			<p className="text-light text-2 mb-2">Copyright © 2023 <a className="fw-600" href="https://www.diezze.ma/" target="_blank">diezze</a>. All Rights Reserved.</p>
		</footer>
	)

	const renderLoadingComponent = () => (
		<section className="hero-wrap">
			<div className="hero-mask opacity-1 bg-dark"></div>
			<div className="hero-bg hero-bg-img"></div>
			<div className="hero-content min-vh-100 d-flex flex-column">
				<Loading type={"spinningBubbles"} color={"#050505"}/>
				{renderFooterComponent()}
			</div>
		</section>
	)

	const renderStartComponent = () => (
		<section className="hero-wrap">
			<div className="hero-mask opacity-1 bg-dark"></div>
			<div className="hero-bg hero-bg-img"></div>
			<div className="hero-content min-vh-100 d-flex flex-column">
				<div className="container py-5 px-4 px-lg-5 my-auto">
					<div className="row py-5 py-sm-4 bg-logo-sign">
						<div className="col-12 text-center mx-auto">
							<h2 className="text-10 fw-600 text-white mb-4 text-anime">{props.t("Bienvenue")} !</h2>
						</div>
					</div>
					<div className="d-flex align-items-center justify-content-center mb-5">
						<button
							onClick={handleNextQuestion}
							className="btn-next">
							<span>{props.t("Démarrer le questionnaire")}</span>
						</button>
					</div>
				</div>
				{renderFooterComponent()}
			</div>
		</section>
	)

	const renderQuestionComponent = (data) => {
		return <section className="hero-wrap">
			<div className="hero-mask opacity-1 bg-dark"></div>
			<div className="hero-bg hero-bg-img"></div>
			<div className="hero-content min-vh-100 d-flex flex-column">
				{
					data.question.type === 'range'
					? <QuestionRange
						language={session.language}
						data={data}
						currentAnswer={answer}
						handleCurrentAnswer={setAnswer}
						rangeValue={rangeValue}
						setRangeValue={setRangeValue}
						handleNextQuestion={handleNextQuestion}/>
					: <QuestionChoice
						language={session.language}
						data={data}
						currentAnswer={answer}
						handleCurrentAnswer={setAnswer}
						handleNextQuestion={handleNextQuestion}/>
				}
				<div className="d-flex align-items-center justify-content-center mb-5">
					<button
						onClick={handleNextQuestion}
						className="btn-next">
						<span>{props.t("Au suivant")}</span>
					</button>
				</div>
				{renderFooterComponent()}
			</div>
		</section>
	};

	const renderSubmitForm = () => (
		<section className="hero-wrap">
			<div className="hero-mask opacity-1 bg-dark"></div>
			<div className="hero-bg hero-bg-img"></div>
			<div className="hero-content min-vh-100 d-flex flex-column">
				<div className="container py-5 px-4 px-lg-5 my-auto">
					{/* <div className="card overflow-hidden"> */}
						<div className="text-center">
							<div className="position-relative">
								<h5 className="text-6 text-white font-size-20">{props.t("Presque fini !")}</h5>
								<p className="text-3 text-white-50 mb-0">{props.t("Soumettez des réponses avec plus d'informations sur vous-même")}</p>
							</div>
						</div>
						<div className="card-body pt-5">
							<div className="p-2">
								<Row>
									<Col>
										<div className="mb-3">
											<label htmlFor="select-age" className="fw-600 text-white">{ props.t("Âge") }</label>
											<select
												id="select-age"
												name="age"
												className="form-control"
												onChange={updateData}>
												<option value={null}>{ props.t("Quel âge avez-vous ?") }</option>
												<option value={ props.t("13-17 ans")}>{ props.t("13-17 ans") }</option>
												<option value={ props.t("18-24 ans") }>{ props.t("18-24 ans") }</option>
												<option value={ props.t("25-34 ans") }>{ props.t("25-34 ans") }</option>
												<option value={ props.t("35-44 ans") }>{ props.t("35-44 ans") }</option>
												<option value={ props.t("45-54 ans") }>{ props.t("45-54 ans") }</option>
												<option value={ props.t("55-64 ans") }>{ props.t("55-64 ans") }</option>
												<option value={ props.t("65-74 ans") }>{ props.t("65-74 ans") }</option>
												<option value={ props.t("75-84 ans") }>{ props.t("75-84 ans") }</option>
												<option value={ props.t("85 et plus") }>{ props.t("85 et plus") }</option>
											</select>
										</div>
									</Col>
									<Col>
										<div className="mb-3">
											<label htmlFor="select-gender" className="fw-600 text-white">{ props.t("Genre") }</label>
											<select
												id="select-gender"
												name="gender"
												className="form-control"
												onChange={updateData}>
												<option value={null}>{ props.t("Choisissez votre sexe") }</option>
												<option value={ props.t("Homme") }>{ props.t("Homme") }</option>
												<option value={ props.t("Femme") }>{ props.t("Femme") }</option>
												<option value={ props.t("Autre") }>{ props.t("Autre") }</option>
											</select>
										</div>
									</Col>
								</Row>
								<Row>
									<Col>
										<div className="mb-3">
											<label htmlFor="select-school-level" className="fw-600 text-white">{ props.t("Niveau scolaire") }</label>
											<select
												id="select-school-level"
												name="schoolLevel"
												className="form-control"
												onChange={updateData}>
												<option value={null}>{ props.t("Quel est votre niveau d'études ?") }</option>
												<option value={ props.t("Bac+5 et plus") }>{ props.t("Bac+5 et plus") }</option>
												<option value={ props.t("Bac+3") }>{ props.t("Bac+3") }</option>
												<option value={ props.t("Bac+2") }>{ props.t("Bac+2" ) }</option>
												<option value={ props.t("Secondaire") }>{ props.t("Secondaire") }</option>
												<option value={ props.t("Collège") }>{ props.t("Collège") }</option>
												<option value={ props.t("Primaire") }>{ props.t("Primaire") }</option>
												<option value={ props.t("Sans") }>{ props.t("Sans") }</option>
											</select>
										</div>
									</Col>
									<Col>
										<div className="mb-3">
											<label htmlFor="select-profession" className="fw-600 text-white">{ props.t("Profession") }</label>
											<select
												id="select-profession"
												name="profession"
												className="form-control"
												onChange={updateData}>
												<option value={null}>{ props.t("Votre profession") }</option>
												<option value={ props.t("Paysan / Artisan / Commerçant") }>{ props.t("Paysan / Artisan / Commerçant") }</option>
												<option value={ props.t("Entrepreneur") }>{ props.t("Entrepreneur") }</option>
												<option value={ props.t("Directeur exécutif") }>{ props.t("Directeur exécutif" ) }</option>
												<option value={ props.t("Cadre supérieur") }>{ props.t("Cadre supérieur") }</option>
												<option value={ props.t("Embauche / Employé") }>{ props.t("Embauche / Employé") }</option>
												<option value={ props.t("À la recherche d'un emploi") }>{ props.t("À la recherche d'un emploi") }</option>
												<option value={ props.t("Étudiant") }>{ props.t("Étudiant") }</option>
												<option value={ props.t("Autre") }>{ props.t("Autre") }</option>
											</select>
										</div>
									</Col>
								</Row>
								<Row>
									<Col>
										<div className="mb-3">
											<label htmlFor="select-region" className="fw-600 text-white"> { props.t("Region") } </label>
											<select
												id="select-region"
												name="region"
												className="form-control"
												onChange={updateData}>
												<option value={null}>{ props.t("Quel est votre région ?") }</option>
												<option value={ props.t("Rabat-Salé-Kenitra") }>{ props.t("Rabat-Salé-Kenitra") }</option>
												<option value={ props.t("Casablanca-Settat") }>{ props.t("Casablanca-Settat") }</option>
												<option value={ props.t("Beni Mellal-Khénifra") }>{ props.t("Beni Mellal-Khénifra") }</option>
												<option value={ props.t("Fès-Meknès") }>{ props.t("Fès-Meknès") }</option>
												<option value={ props.t("Marrakech-Safi") }>{ props.t("Marrakech-Safi") }</option>
												<option value={ props.t("Tanger-Tétouan-Al Hoceïma") }>{ props.t("Tanger-Tétouan-Al Hoceïma") }</option>
												<option value={ props.t("Oriental") }>{ props.t("Oriental") }</option>
												<option value={ props.t("Souss-Massa") }>{ props.t("Souss-Massa") }</option>
												<option value={ props.t("Guelmim-Oued Noun") }>{ props.t("Guelmim-Oued Noun") }</option>
												<option value={ props.t("Laâyoune-Sakia El Hamra") }>{ props.t("Souss-Massa") }</option>
												<option value={ props.t("Dakhla-Oued Ed-Dahab") }>{ props.t("Dakhla-Oued Ed-Dahab") }</option>
												<option value={ props.t("Autre") }>{ props.t("Autre") }</option>
											</select>
										</div>
									</Col>
									<Col>
										<div className="mb-3">
											<label htmlFor="select-ville" className="fw-600 text-white">{ props.t("Ville") }</label>
											<select
												id="select-ville"
												name="ville"
												className="form-control"
												onChange={updateData}>
												<option value={null}>{ props.t("Quel est votre ville ?") }</option>
												{villes && villes.map((e, i) => (<option key={i} value={e}>{e}</option>))}
												<option value={ props.t("Autre") }>{ props.t("Autre") }</option>
											</select>
										</div>
									</Col>
								</Row>
								<Row>
									<Col>
										<div className="mb-3">
											<label htmlFor="select-situation-familiale" className="fw-600 text-white">{ props.t("Situation familiale") }</label>
											<select
												id="select-situation-familiale"
												name="familySituation"
												className="form-control"
												onChange={updateData}>
												<option value={null}>{ props.t("Quel est votre situation familiale ?") }</option>
												<option value={ props.t("Célibataire") }>{ props.t("Célibataire") }</option>
												<option value={ props.t("Marié et père / Mère d'un enfant de moins de 6 ans") }>{ props.t("Marié et père / Mère d'un enfant de moins de 6 ans") }</option>
												<option value={ props.t("Marié et père / Mère d'un enfant de moins de 13 ans") }>{ props.t("Marié et père / Mère d'un enfant de moins de 13 ans" ) }</option>
												<option value={ props.t("Marié et père / Mère d'un enfant de plus de 14 ans") }>{ props.t("Marié et père / Mère d'un enfant de plus de 14 ans") }</option>
												<option value={ props.t("Marié sans enfant") }>{ props.t("Marié sans enfant") }</option>
												<option value={ props.t("Veuf / Veuve") }>{ props.t("Veuf / Veuve") }</option>
											</select>
										</div>
									</Col>
								</Row>
								<div className="d-flex align-items-center justify-content-center mt-3 mb-5">
									<button
										onClick={() => toggle()}
										className="btn-next"
										type="submit">
										<span>{ props.t("Soumettre") }</span>
									</button>
								</div>
							</div>
						</div>
					{/* </div> */}
				</div>
			</div>
		</section>
	)

	const renderResultSubmit = (data) => {
		const content = personalityTypesData[data.personalityType];
		
		return (
			<section className={`background-${data.personalityType}`}>
				<div className="hero-mask opacity-1 bg-dark"></div>
				<div className="hero-bg hero-bg-img"></div>
				<div className="hero-content min-vh-100 d-flex flex-column">
					<div className="row" style={{ paddingTop: "7rem", ...session.language == "ar" && {direction: "rtl"} }}>
						<div className="col-4 py-5 px-4 px-lg-5 my-auto">
							<div className="row py-5 py-sm-4">
								<div className="col-12 text-center mx-auto">
									<img src={content.gif} style={{ width: "350px" }}/>
								</div>
							</div>
							<div className="d-flex align-items-center justify-content-center mb-5">
								<button
									style={{ top: -30, width: "350px" }}
									onClick={() => { setSubmited(false) }}
									className="btn-next">
									<span>{ props.t("Relancer le quiz") }</span>
								</button>
							</div>
						</div>
						<div className="col-6">
							<h2 className="text-8 fw-600 text-white text-anime" style={{ marginBottom: "30px" }}>
								<span>{content.firstTitle}</span> 
								<p>{content.secondTitle}</p>
							</h2>
							{
								content.data
									.map((e, i) => (
									<div key={i} style={{ marginBottom: "40px" }}>
										{e.title !== "" && (<h3 className="fw-600 text-white text-anime mb-2">{e.title} :</h3>)}
										{e.descr !== "" && (<p className="fw-600 text-white text-anime">{e.descr}</p>)}
									</div>
								))
							}
						</div>
					</div>
				</div>
			</section>
		)
	}

	return (
		<>
			<Toaster />
			<Modal isOpen={modal} toggle={toggle} centered returnFocusAfterClose={true}>
				<ModalHeader toggle={toggle} close={() => {}}>Confirmation</ModalHeader>
				<ModalBody>
					{props.t("Est ce que vous confirmer envoi de votre informations ?")}
				</ModalBody>
				<ModalFooter>
				<Button size="sm" color="primary" onClick={() => { handleSubmitAnswers(); toggle(); }}>
					{props.t("Confirmer")}
				</Button>{' '}
				<Button size="sm" color="secondary" onClick={toggle}>
					{props.t("Annuler")}
				</Button>
				</ModalFooter>
			</Modal>
			<div id="main-wrapper" className="bg-primary">
				{progressValue && progressValue !== 0 && progressValue !== "0.00"
					? <Progress id="progress-questions" value={parseInt(progressValue)} min={0} max={100} />
					: <></>
				}
				<header id="header">
					<nav className="primary-menu navbar navbar-expand-md navbar-text-light bg-transparent border-bottom-0 py-4">
						<div className="container position-relative">
							<div className="col-auto col-lg-2"> 
								<a className="logo" href="/" title="Zoon"> <img src="https://www.diezze.ma/wp-content/uploads/2022/11/logo-blanc_Plan-de-travail-1-copie-30.png" alt="Zoon" width="200" /> </a> 
							</div>
							<div className="col col-lg-8 navbar-accordion px-0">
								<button className="navbar-toggler ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#header-nav"><span></span><span></span><span></span></button>
							</div>
							<div className="col-auto col-lg-2 d-flex justify-content-end">
								<button
									className={session.language === "fr" ? "lang-btn-active lang-btn" : "lang-btn"}
									onClick={() => {handleChangeLanguage("fr")}}>
									<span className="lang-label">Fr</span>
								</button>
								<button
									className={session.language === "ar" ? "lang-btn-active lang-btn" : "lang-btn"}
									onClick={() => {handleChangeLanguage("ar")}}>
									<span className="lang-label">Ar</span>
								</button>
							</div>
						</div>
					</nav>
				</header>
				{
					loading
					? renderLoadingComponent()
					: (submited && dataParticipant)
						? renderResultSubmit(dataParticipant.data)
						: (session.step === 0)
							? renderStartComponent()
							: (session.step > questionsList.length)
								? renderSubmitForm()
								: activeQuestion && renderQuestionComponent(activeQuestion)
				}
			</div>
		</>
	);
}

export default withTranslation()(HomeQuiz)