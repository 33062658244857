import React from "react"
import { Row } from "reactstrap"
import DatatableTables from "../Tables/DatatableTables"


const Dashboard = () => {

  return (
    <React.Fragment>
      <DatatableTables />
    </React.Fragment>
  )
}

export default Dashboard