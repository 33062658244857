import { combineReducers } from "redux"
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// Front
import Layout from "./layout/reducer"
// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"
//Calendar
import calendar from "./calendar/reducer"
//chat
import chat from "./chat/reducer"
//contacts
import contacts from "./contacts/reducer"
//tasks
import tasks from "./tasks/reducer"
import sessionReducer from "../redux/slices/sessionSlice"
import profileReducer, { profileApi } from "../redux/slices/profileSlice"
import { personalityTypesApi } from "../redux/slices/personalityTypes.slice"
import { questionsApi } from "../redux/slices/questionsSlice"
import { answersApi } from "../redux/slices/answersSlice"

const persistConfig = {
	key: 'session',
	storage,
	blacklist: ['Layout', 'Login', 'Account', 'ForgetPassword', 'Profile', 'calendar', 'chat', 'tasks', 'contacts', 'personalityTypesApi', 'questionsApi', 'profileApi', 'answersApi'],
	version: 1
}

const combinedReducers = combineReducers({
	// public
	Layout,
	Login,
	Account,
	ForgetPassword,
	Profile,
	calendar,
	chat,
	tasks,
	contacts,
	'session': sessionReducer,
	'userProfile': profileReducer,
	[profileApi.reducerPath]: profileApi.reducer,
	[personalityTypesApi.reducerPath]: personalityTypesApi.reducer,
	[questionsApi.reducerPath]: questionsApi.reducer,
	[answersApi.reducerPath]: answersApi.reducer,
})

const persistedReducer = persistReducer(
	persistConfig,
	combinedReducers
)

export default persistedReducer;